import React from "react";
import { Link } from "gatsby"
import PropTypes from "prop-types";

// import Emoji from "./emoji"
import MountainRange from "-!svg-react-loader?props[]=className:w-full!../../assets/svg/vector-mountain-range.svg";

export default function LayoutDe({ children }) {
  return (
    <>
      <>{children}</>
      <div className="items-center bg-primary-bg mx-auto divide-y divide-gray-400 max-w-screen md:grid md:grid-cols-2 md:divide-y-0 border-gray-400 border-t">
      <MountainRange className='absolute max-w-full hidden md:block' />
      <div className='self-start z-10 border-gray-400 md:border-r'>
        <div className="max-w-xl p-12 mx-auto md:py-12 lg:py-24">
        <h3 className="h1 lg:mb-8">Rentware</h3>
          <div className="mb-2 text-lg">
            Betterware Software UG<br/>
            Ernst-Augustin-Str. 12<br/>
            12489 Berlin<br/>
            Germany
          </div>
        </div>
      </div>
      <div className='self-start z-10'>
        <div className="max-w-xl p-12 mx-auto md:py-12 lg:py-24 lg:text-lg">
          <h3 className="h1 lg:mb-8"><Link to="/de/kontakt">Kontakt</Link></h3>
          <div className="flex flex-wrap gap-6 md:gap-12 md:flex-row md:flex-no-wrap">
            <div>
              <address className="not-italic">
                <div className="mb-2 text-lg">
                  E-Mail: <a className="link" href="mailto:support@rentware.com">support@rentware.com</a><br/>
                  Tel.: <a href="tel:+490309203854015">+49 (0)30 920 385 4015</a>
                </div>
              </address>
            </div>
          </div>
        </div>
      </div>
    </div>
      <div className="footer py-4 text-base">
      <div className='md:grid grid-cols-3 max-w-screen-xl mx-auto p-4 md:p-6'>
      <p className='text-center pb-4 md:text-left md:pb-0'> {new Date().getFullYear()} © Rentware</p>
      <ul className="text-center">
        <li><Link to="/de/impressum">Impressum</Link></li>
        <li><Link to="/de/datenschutz">Datenschutz</Link></li>
        <li><Link to="/de/kontakt">Kontakt</Link></li>
      </ul>
      </div>
    </div>    </>
  )
}

LayoutDe.propTypes = {
  children: PropTypes.node.isRequired,
};