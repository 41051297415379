import React from "react";
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "../../components/alternative/layout-alt.de";
import Nav from "../../components/alternative/Nav-alt.de"
import Seo from "../../components/seo";
import ContactFormComponent from "../../components/ContactFormSupport.de"


export const query = graphql`query SupportPageDeQuery {
  happyCustomer: file(relativePath: {eq: "working-rentware.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 740, layout: CONSTRAINED)
    }
  }
}
`

const ContactPageDe = ({ data }) => {
  // useCalendlyInline();
  return (
    <Layout>
      <Seo
        seo={{
          metaTitle: "Support",
          metaDescription: "Wir sind für Sie da",
        }}
      />
      <Nav />
      <div className="grid mx-auto md:grid-cols-2 lg:max-w-screen-xl lg:mt-12">
        <div className="max-w-2xl p-4 mb-4 md:p-6 md:my-6 lg:mt-12 lg:mb-24">
        <div className="font-bold mb-6"><span className="dot"></span> <span className="pl-4 absolute">Systemstatus: Keine Probleme bekannt</span></div>
          <h1>Haben Sie Fragen?</h1>
          <p>Unsere Mitarbeiter helfen Ihnen gerne. Schreiben Sie uns Ihr Anliegen oder rufen Sie uns einfach an.</p>
          <div className="mb-2 mt-8 font-bold">Support Team Kontakt</div>
          <a href="tel:+49309203854012" className="my-2 mr-2 btn btn--tertiary">+49 (0)30 920 385 40 15</a>
          <a href="mailto:support@rentware.com" className="my-2 underline btn btn--tertiary">support@rentware.com</a>
          <div className="mt-6 lg:mt-12 mb-6 font-bold">Kontaktformular</div>
          <ContactFormComponent />
        </div>
        <div className="w-full max-w-2xl place-self-start p-4 mb-4 md:p-6 md:my-6">
        <GatsbyImage
          image={data.happyCustomer.childImageSharp.gatsbyImageData}
          className="w-full"
          alt="team support" />
        </div>
      </div>
    </Layout>
  );
}

export default ContactPageDe;