import React from "react"
import { Link } from "gatsby"
import { navigate } from "gatsby-link"

function encode(data) {
  const formData = new FormData()

  for (const key of Object.keys(data)) {
    formData.append(key, data[key])
  }

  return formData
}

function ContactFormDeComponent() {
  const [state, setState] = React.useState({})

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error))
  }

  return (
    <section
      className="relative"
    >
      <form
        name="Support | de"
        method="post"
        action="/de/thank-you-support"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
        className="contact-form"
      >
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input type="hidden" name="form-name" value="Support | de" />
        <p hidden>
          <label>
            Don’t fill this out:
            <input name="bot-field" onChange={handleChange} />
          </label>
        </p>

        <div className="grid gap-2 md:grid-cols-2 md:gap-4">
        <div className="col-span-1">
            <label htmlFor="first-name">Name</label>
            <input
              placeholder="Hugo"
              id="first-name"
              type="text"
              onChange={handleChange}
              name="firstName"
              required
            />
          </div>
          <div className="col-span-1">
            <label htmlFor="kundenNummer">Kundennummer</label>
            <input
              placeholder="12345"
              id="first-name"
              type="text"
              onChange={handleChange}
              name="kundenNummer"
              required
            />
          </div>
          <div className="md:row-start-2">
            <label htmlFor="email">E-Mail</label>
            <input
              placeholder="hugo@vermietung.de"
              id="email"
              type="email"
              onChange={handleChange}
              name="email"
              required
            />
          </div>
          <div className="md:row-start-2">
            <label htmlFor="phone">Telefon (optional)</label>
            <input
              placeholder="+1 3193 381 3913"
              id="phone"
              type="tel"
              onChange={handleChange}
              name="phone"              
            />
          </div>
          <div className="md:col-span-2 md:row-start-3">
            <label htmlFor="message">Anliegen:</label>
            <textarea
              className="form-textarea"
              id="message"
              placeholder="Bitte beschreiben Sie Ihr Anliegen"
              rows="3"
              onChange={handleChange}
              name="message"
              required
            />
          </div>
        </div>

        <div className="flex md:col-span-2">
          <label className="flex items-center text-sm md:text-base">
            <input type="checkbox" className="form-checkbox" required />
            <span className="ml-3 text-gray-400">
            Ich stimme zu, dass meine Angaben aus dem Kontaktformular zur Beantwortung meiner Anfrage erhoben und verarbeitet werden. Ausführliche Informationen zum Umgang mit Nutzerdaten finden Sie in unserem&nbsp;
              <Link to="/de/datenschutz" title="Unsere Datenschutzbestimmungen" className="underline">
                Datenschutz
              </Link>.
            </span>
          </label>
        </div>

        <button className="block px-6 py-2 my-4 ml-auto font-bold text-white rounded bg-secondary-font">
          Nachricht abschicken
        </button>
      </form>
    </section>
  )
}

export default ContactFormDeComponent
